import { Button } from "react-bootstrap";
import EnvConfig from "../../envConfig";
import "./wizard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faClock, faTrashCan } from "@fortawesome/free-solid-svg-icons";

import { formatDate, formatTime } from "../../utilities/functions";

function StepConfirmation(props) {
  const {
    handleImageError,
    bookedSlotsData,
    navigateToBack,
    coachData,
    slotBook,
    reschedule,
    slotBooking,
    removeSlots,
    pageError,
  } = props;
  const handleBackAction = () => {
    navigateToBack();
  };

  return (
    <>
      <div className="page-heading">
        <h4 className="col hd-txt">Confirm Appointment </h4>
        <Button variant="clr-primary" className="btn-add mx-4" onClick={handleBackAction}>
          Back
        </Button>
      </div>
      {pageError && <div className="error-msg col-lg-4 ms-2 text-align-start" >{pageError}</div>}
      <div>
        <div className="skill-card-no-click col-lg-4">
        <div className="profile-wrap coach-Img"  style={{
                backgroundImage:`url(${EnvConfig.MediaBase}/${coachData?.assetsDir}/${coachData?.profileImage})`,
              backgroundPosition: "center", /* Center the image */
              backgroundRepeat:'no-repeat', /* Do not repeat the image */
              backgroundSize: "cover", /* Resize the background image to cover the entire container*/
              borderRadius:"50%"
              }}>
                  
                </div>
          
          <div className="skill-detail align-self-center">
            <div className="title coach-name" style={{paddingTop:"0px"}}> {`${coachData?.firstName} ${coachData?.lastName}`}</div>
          </div>
        </div>

        <div className="">
          {bookedSlotsData.map((slots, index) => (
            <div className="skill-card-time-management col-lg-4" key={index}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div>Coaching Appointment Details</div>
                <div>
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    onClick={() => {
                      removeSlots(slots?.coachSubSlot);
                    }}
                    role="button"
                  />
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", padding: "5px 0px" }}>
                <div>
                  <FontAwesomeIcon size="lg" icon={faCalendar} color="#3d3e45" />
                </div>
                <div style={{ paddingInline: "10px" }}>{formatDate(slots?.fromDateTime)}</div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", padding: "5px 0px" }}>
                <div>
                  <FontAwesomeIcon size="lg" icon={faClock} />
                </div>
                <div style={{ paddingInline: "10px" }}>
                  {formatTime(slots?.fromDateTime)} - {formatTime(slots?.toDateTime)}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="select-time-slot col-lg-4">
          <Button
            variant="clr-primary"
            type="submit"
            disabled={bookedSlotsData?.length === 0}
            className="mt-2 w-100 px-3 ms-2"
            onClick={reschedule ? slotBook : slotBooking}
          >
            {reschedule ? "Reschedule Appointment" : "Confirm Appointment"}
          </Button>
        </div>
      </div>
    </>
  );
}

export default StepConfirmation;
