import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Button, Tab, Nav } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { useDispatch, useSelector } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { setRouteData } from "../../stores/appSlice";
import { CoachRatingServices, BookSlotServices } from "../../services";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCircleCheck, faClock } from "@fortawesome/free-regular-svg-icons";
// import moment from "moment";
import * as Yup from "yup";
import EnvConfig from "../../envConfig";
import Profile from "../../assets/images/avtar.png";
import { Rating } from "react-simple-star-rating";
import { Formik } from "formik";
import coachRatingService from "../../services/coachRating.service";
import { toast } from "react-toastify";
import { copyObjectByKeys, formatDate, formatTime } from "../../utilities/functions";
import DeleteModal from "../CustomComponent/DeleteAppointment";

function AppointmentList({ pageTitle }) {
  const dispatch = useDispatch();
  const params = useParams();
  const { authState } = useSelector((state) => state);
  const navigate = useNavigate();
  const [formObject, set_formObject] = useState(getFormObject());
  const [activeTab, set_activeTab] = useState("tab01");
  const [coachId, set_coachId] = useState(params.coachId);
  const [appointmentList, set_appointmentList] = useState();
  const [bookingRating, set_bookingRating] = useState({});
  const [inProgessList, set_inProgessList] = useState([]);
  const [completedList, set_completedList] = useState([]);
  const [pageError, set_pageError] = useState("");
  const [selectedAppoint, setselectedAppoint] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [abortData, setabortData] = useState(false);
  const [setError, set_Error] = useState();

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, []);
  useEffect(() => {
    if (coachId) fetchBooking();
  }, [coachId]);

  useEffect(() => {
    if (appointmentList) {
      categoriseAppointment();
      //fetchBookingRating();
    }
  }, [appointmentList]);

  useEffect(() => {
    if (bookingRating.id) populateForm();
  }, [bookingRating.id]);

  function getFormObject() {
    return {
      rating: 0,
      // review: "",
    };
  }

  const FormSchema = Yup.object().shape({
    rating: Yup.number().required("Rating is Required"),
    // review: Yup.string().required("Detail is Required"),
  });

  async function handleFormSubmit(values) {
    try {
      const resp = bookingRating.id
        ? await trackPromise(coachRatingService.updateCoachRating(bookingRating.id, values))
        : await trackPromise(coachRatingService.createCoachRating(formatForm(values)));

      const { data } = resp;
      if (data.success) {
        toast.success("Coach Rating Updated");
        fetchBookingRating();
      } else {
        set_pageError(data.message || "Registeration failed");
      }
    } catch (err) {
      console.error("Register error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
      toast.error(err.response.data.message || err.message || "Register error");
    }
  }

  function formatForm(values) {
    const newForm = { ...values };
    newForm.coach = appointmentList.coach._id;
    newForm.topic = appointmentList.topic._id;
    newForm.booking = coachId;
    return newForm;
  }

  function populateForm() {
    let formobj = { ...formObject };
    copyObjectByKeys(formobj, bookingRating);
    // formobj.accreditationLevel = coachDetails.accreditationLevel?.id;
    set_formObject({ ...formobj });
  }

  // async function fetchBooking() {
  //   try {
  //     const resp = await trackPromise(BookingServices.getAppointmentDetail(bookingId));
  //     const { data } = resp;

  //     if (data.success) {
  //       set_appointmentList(data.data);
  //     } else {
  //       set_pageError(data.message || "Error in fetching Coaches");
  //     }
  //   } catch (err) {
  //     console.error("Fetch Coaches catch => ", err);
  //     if (err.code !== "ERR_NETWORK") {
  //       set_pageError(err.response.data.message);
  //     } else {
  //       set_pageError(err.message);
  //     }
  //   }
  // }

  async function fetchBooking() {
    try {
      const resp = await trackPromise(BookSlotServices.getBookSlotsByAssociatedUser(coachId));
      const { data } = resp;

      if (data.success) {
  const sortedAppointments = data.data.sort(
        (a, b) => new Date(a.fromDateTime) - new Date(b.fromDateTime),
      );
        set_appointmentList(sortedAppointments);
      } else {
        set_pageError(data.message || "Error in fetching Coaches");
      }
    } catch (err) {
      console.error("Fetch Coaches catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function fetchBookingRating() {
    try {
      const resp = await trackPromise(CoachRatingServices.getCoachRatingByBooking(coachId));
      const { data } = resp;

      if (data.success) {
        if (data.data) {
          set_bookingRating(data.data);
        }
      } else {
        set_pageError(data.message || "Error in fetching Coaches");
      }
    } catch (err) {
      console.error("Fetch Coaches catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  function categoriseAppointment() {
    const inProgess = [],
      completed = [];
    for (let i = 0; i < appointmentList.length; i++) {
      let bookingItem = appointmentList[i];

      if (
        bookingItem.slotStatus === "in-progress" ||
        bookingItem.slotStatus === "confirmed" ||
        bookingItem.slotStatus === "cochee-joined" ||
        bookingItem.slotStatus === "coach-joined"
      ) {
        inProgess.push(bookingItem);
      }
      if (bookingItem.slotStatus === "completed") {
        completed.push(bookingItem);
      }
    }
    set_inProgessList(inProgess);
    set_completedList(completed);
  }

  function convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    // newDate.setHours(hours - offset);

    return newDate;
  }

  function navToSlotDetail(itm) {
    navigate(`/${authState.user.organisation.orgSlug}/manageCoaching/slotDetail/${itm.subSlot}`);
  }

  const handleImageError = (e) => {
    e.target.src = Profile; // Set the default image source
  };

  const handleEventClick = (id) => {
    setselectedAppoint(id);
    //deleteAppointApi(id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setselectedAppoint(null);
    setIsModalOpen(false);
    setabortData(false);
    set_Error("");
  };

  const deleteAppointApi = async (id) => {
    const requestData = {
      forceAbort: abortData,
    };

    try {
      const resp = await BookSlotServices.cancelSlot(id, requestData);
      const { data } = resp;
      setabortData(false);
      if (data.success) {
        if (data.data.confirmToForce) {
          setabortData(true);
          set_Error(data.message);
          setabortData(true);

          //toast.success("Appointment Cancel Sucessfully");
          //set_Error("");
        } else {
          fetchBooking();
          //toast.error(data.message);
          set_pageError(data.message);
          setIsModalOpen(false);
        }
      } else {
        set_Error(data.message || "Cancel error");
        // toast.error(data.message || "Cancel error");
        set_pageError(data.message || "Error in deleting slots");
      }
    } catch (err) {
      //toast.error(err.response.data.message || "Cancel error");
      set_Error(err.response.data.message || "Cancel error");
      console.error("Fetch Cancel slots catch => ", err);
      set_pageError(err);
    }
  };

  const deleteAppoint = async (id) => {
    deleteAppointApi(id, abortData);
    if (abortData) {
      closeModal();
    }
    fetchBooking();
  };

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="col hd-txt">Appointments</h4>
      </div>
      {appointmentList && (
        <>
          <Row lg="auto">
            <Row>
              {/* <div className="profile-wrap pe-1">
                <Image
                  src={`${EnvConfig.MediaBase}/${appointmentList[0]?.coach?.assetsDir}/${appointmentList[0]?.coach?.profileImage}`}
                  className="img-fluid coach-Img"
                  alt=""
                />

              </div> */}
              <div className="profile-wrap coach-Img"  style={{
                backgroundImage:`url(${EnvConfig.MediaBase}/${appointmentList[0]?.coach?.assetsDir}/${appointmentList[0]?.coach?.profileImage})`,
              backgroundPosition: "center", /* Center the image */
              backgroundRepeat:'no-repeat', /* Do not repeat the image */
              backgroundSize: "cover", /* Resize the background image to cover the entire container*/
              borderRadius:"50%"
              }}>
                  
                </div>
            </Row>
            <Col className="skill-detail align-self-center">
              <div className=" coach-name">{`${appointmentList[0]?.coach?.firstName} ${appointmentList[0]?.coach?.lastName}`}</div>
              <div className="">{bookingRating?.coach?.accreditationLevel?.levelName}</div>
            </Col>
          </Row>
          <div className="my-2">
            Your Appointments with Coach{" "}
            {`${appointmentList[0]?.coach?.firstName} ${appointmentList[0]?.coach?.lastName}`} are shown below
          </div>
        </>
      )}
      <div className="tab-thm-01 mt-3">
        <Tab.Container defaultActiveKey={activeTab}>
          <Row className="g-0 mb-4">
            <Nav variant="tabs" className="">
              <Nav.Link className="" eventKey="tab01">
                Upcoming
              </Nav.Link>
              <Nav.Link className="" eventKey="tab02">
                Completed
              </Nav.Link>
              <Nav.Link className="" eventKey="tab03">
                All
              </Nav.Link>
              {/* <Nav.Link className="" eventKey="tab04">
                Rating
              </Nav.Link> */}
            </Nav>
          </Row>
          <Row className="g-0">
            <Tab.Content>
              <Tab.Pane eventKey="tab01">
                {inProgessList?.length ? (
                  <AppointmentTable
                    navToSlotDetail={navToSlotDetail}
                    tblData={inProgessList}
                    coach={appointmentList[0]?.coach}
                    topic={appointmentList[0].topic}
                    handleEventClick={handleEventClick}
                    authState={authState}
                    navigate={navigate}
                  />
                ) : (
                  <div></div>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="tab02">
                {completedList?.length > 0 ? (
                  <AppointmentTable
                    navToSlotDetail
                    tblData={completedList}
                    coach={appointmentList[0]?.coach}
                    topic={appointmentList[0].topic}
                    authState={authState}
                    navigate={navigate}
                  />
                ) : (
                  <div></div>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="tab03">
                {appointmentList?.length ? (
                  <AppointmentTable
                    navToSlotDetail={navToSlotDetail}
                    tblData={appointmentList}
                    coach={appointmentList[0]?.coach}
                    topic={appointmentList[0].topic}
                    handleEventClick={handleEventClick}
                    authState={authState}
                    navigate={navigate}
                  />
                ) : (
                  <div></div>
                )}
              </Tab.Pane>
            </Tab.Content>
          </Row>
        </Tab.Container>
      </div>
      {selectedAppoint && (
        <DeleteModal
          id={selectedAppoint}
          isOpen={isModalOpen}
          onClose={closeModal}
          deleteAppoint={deleteAppoint}
          pageError={setError}
        />
      )}
      {/* </>
      ))} */}
    </Container>
  );
}

export default AppointmentList;

function AppointmentTable({ tblData = [], navToSlotDetail, coach, topic, handleEventClick, authState, navigate }) {
  return (
    <div className="container-fluid">
      {tblData.map((itm, inx) => (
        <Col lg={6} xl={6} xxl={6} key={inx} className="inner-card-border my-2 mb-3">
          <Row className="mx-1 title-name">
            <Col className="d-flex justify-content-end ps-0">
              {/* <div>{`${itm.topic.topicName} `}</div> */}

              {/* {itm.slotStatus === "confirmed" && (
                <Button
                  variant=""
                  size="md"
                  className=""
                  onClick={() => handleEventClick(itm._id)}
                  style={{ float: "right" }}
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </Button>
              )} */}
            </Col>
          </Row>
          <div className="d-flex justify-content-start flex-md-row flex-lg-column flex-xl-row  flex-column mx-1 my-1">
            <div className="me-3 mb-2">
              <FontAwesomeIcon icon={faCalendar} />
              <span className="mx-2">{formatDate(itm.fromDateTime)}</span>
            </div>
            <div className="me-3 mb-2">
              <FontAwesomeIcon icon={faClock} />
              <span className="mx-2">
                {formatTime(itm?.fromDateTime)} - {formatTime(itm?.toDateTime)}
              </span>
            </div>
            <div className="mb-2">
              <FontAwesomeIcon icon={faCircleCheck} />
              <span className="mx-2 text-capitalize">{itm.slotStatus}</span>
            </div>
          </div>

          <div className="d-flex justify-content-start flex-row mx-1 ">
            <Button as={Link} to={itm._id} variant="clr-primary" size="sm" className="view-btn px-3 py-2 ms-0 mt-1">
              View Appointment
            </Button>
            {itm.slotStatus === "confirmed" ? (
              <Button
                variant="clr-transparent"
                size="sm"
                className="view-btn px-3 py-2 ms-0 mt-1"
                onClick={() => {
                  navigate(`/${authState.user.organisation.orgSlug}/bookslots`, {
                    replace: true,
                    state: {
                      coach: coach?._id,
                      coachData: coach,
                      topic: topic?._id,
                      appId: itm._id,
                      reschedule: true,
                    },
                  });
                }}
              >
                Reschedule Appointment
              </Button>
            ) : (
              <></>
            )}
            {itm.slotStatus === "confirmed" ? (
              <Button
                variant="clr-transparent"
                size="sm"
                className="view-btn px-3 py-2 ms-0 mt-1"
                onClick={() => handleEventClick(itm._id)}
              >
                Delete Appointment
              </Button>
            ) : (
              <></>
            )}
          </div>
        </Col>
      ))}
    </div>
  );
}
