import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Image from "react-bootstrap/Image";
import EnvConfig from "../../envConfig";
import { trackPromise } from "react-promise-tracker";
import { UserService, CoachRatingServices } from "../../services";
import "./wizard.css";
import { replaceWithBr } from "../../utilities/functions";

function CoachDetail(props) {
  const { handleImageError, navigateToNext, topicId, coachId, coachData, setCoachProfile } = props;
  const handleBackAction = () => {
    setCoachProfile(0);
  };
  const [key, setKey] = useState("about");
  const [coachProfiledet, setCoachProfiledet] = useState({});
  const [coachRatingdet, setCoachRatingdet] = useState([]);
  const [pageError, set_pageError] = useState("");
  const [coachProfilevideo, setCoachProfilevideo] = useState();

  useEffect(() => {
    fetchCoachProfile();
    fetchCoachRating();
  }, []);

  async function fetchCoachProfile() {
    try {
      const resp = await trackPromise(UserService.getCoachDetails(topicId, coachId));
      const { data } = resp;

      if (data.success) {
        setCoachProfiledet(data.data);
        setCoachProfilevideo(data.data.profileVideo);
      } else {
        set_pageError(data.message || "Error in fetching coach details");
      }
    } catch (err) {
      console.error("Fetch coach details catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function fetchCoachRating() {
    try {
      const resp = await trackPromise(CoachRatingServices.getCoachRating(coachId, topicId));
      const { data } = resp;

      if (data.success) {
        setCoachRatingdet(data.data);
      } else {
        set_pageError(data.message || "Error in fetching coach details");
      }
    } catch (err) {
      console.error("Fetch coach details catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }
  console.log("profile", coachProfiledet);

  const videoUrl =
    Object.keys(coachProfiledet).length !== 0 &&
    `${EnvConfig?.MediaBase}/${coachProfiledet?.assetsDir}/${coachProfiledet?.profileVideo}`;

  return (
    <>
      <div className="page-heading">
        <h4 className="col hd-txt">Coach Details</h4>
        <Button variant="clr-primary" className="btn-add back-continue mx-4" onClick={handleBackAction}>
          Back
        </Button>
        {/* <Button
          role="button"
          variant="clr-primary"
          className="btn-add back-continue"
          disabled={!coachId}
          onClick={navigateToNext}
        >
          Continue
        </Button> */}
      </div>
      <div className="row">
        <div className="col-lg-5 inner-card-border p-3">
          <div className="skill-container">
            <div className="d-block profile-detail">
              <div className="row no-gutters">
                <div className="col-auto pe-0 ">
                  {/* <Image
                    src={`${EnvConfig?.MediaBase}/${coachProfiledet?.assetsDir}/${coachProfiledet?.profileImage}`}
                    className="coach-Img img-fluid"
                    alt=""
                  /> */}
                   <div className="profile-wrap coach-Img"  style={{
                backgroundImage:`url(${EnvConfig.MediaBase}/${coachProfiledet?.assetsDir}/${coachProfiledet?.profileImage})`,
              backgroundPosition: "center", /* Center the image */
              backgroundRepeat:'no-repeat', /* Do not repeat the image */
              backgroundSize: "cover", /* Resize the background image to cover the entire container*/
              borderRadius:"50%"
              }}>
                  
                </div>
                </div>
                <div className="col align-self-center">
                  <div className="card-block px-2 coach-rating-div">
                    <h5 className="card-title">
                      {coachProfiledet?.firstName} {coachProfiledet?.lastName}
                    </h5>
                    <div
                      style={{
                        background: `linear-gradient(90deg, #ff643d`,
                      }}
                    >
                      <span>★{Math.round(coachProfiledet?.avgRating * 10) / 10}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-block coach-video-container mt-3">
                <video style={{ height: "auto", width: "100%" }} controls="controls" src={videoUrl}>
                  Sorry, your browser doesn't support videos.
                </video>
              </div>

              <div className="py-5">
                {/* <h4 className="card-title mb-3">About {coachProfiledet?.firstName}</h4> */}
                {/* <div className="about-container">
                  <div dangerouslySetInnerHTML={{ __html: replaceWithBr(coachProfiledet?.aboutSelf) }}></div>
                </div> */}
                <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3 ">
                  <Tab eventKey="about" title="About">
                    <div className="about-container">
                      <div dangerouslySetInnerHTML={{ __html: replaceWithBr(coachProfiledet?.aboutSelf) }}></div>
                    </div>
                  </Tab>
                  <Tab eventKey="experienceAchieved" title="Experience and Achievements">
                    <div className="about-container">
                      <div
                        dangerouslySetInnerHTML={{ __html: replaceWithBr(coachProfiledet?.experienceAchieved) }}
                      ></div>
                    </div>
                  </Tab>
                </Tabs>
                <div className="text-center py-3">
                  <Button
                    role="button"
                    variant="clr-primary"
                    className="btn-add back-continue text-center"
                    disabled={!coachId}
                    onClick={navigateToNext}
                  >
                    Check Availability
                  </Button>
                </div>
              </div>

              {/* <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
                <Tab eventKey="about" title="About">
                  <div className="about-container">
                    <div dangerouslySetInnerHTML={{ __html: replaceWithBr(coachProfiledet?.aboutSelf) }}></div>
                  </div>
                </Tab>
                <Tab eventKey="other" title="Other">
                  <h6>Topics Of Interest</h6>
                  <ul>
                    {coachProfiledet?.topics?.map((itm, index) => {
                      return <li key={index}>{itm?.topicName}</li>;
                    })}
                  </ul>

                  <h6>Skills</h6>
                  <p>{coachProfiledet?.skills}</p>
                </Tab>
              </Tabs> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CoachDetail;
