import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import EnvConfig from "../../envConfig";
import CustomizedCalender from "./CustomizedCalender";
import "./wizard.css";
import { formatTime } from "../../utilities/functions";
import { useSelector } from "react-redux";
import moment from "moment";

function StepSlotSelection(props) {
  const {
    handleImageError,
    navigateToNext,
    coachData,
    selectedDate,
    setSelectedDate,
    timeSlot,
    navigateToBack,
    setConfirmSlots,
    setSlotSelected,
    confirmSlots,
    slotSelected,
    dateAvailable,
    handleEventMonthChange,
    bookedDates,
    setBookedSlotsData,
    minBookigCount,
    bookingExist,
    reschedule,
  } = props;
  const [selectedData, setSelectedData] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [selectedSingleData, setSelectedSingleData] = useState(confirmSlots);
  const [activeSlotId, setActiveSlotId] = useState([]);
  const { authState } = useSelector((state) => state);
  const endDate = moment(authState?.user?.organisation?.endDate).format();
  const hasGoal = authState?.user?.hasGoal;

  useEffect(() => {
    disabledFn();
  }, [selectedData]);

  const handleBackAction = () => {
    setSelectedDate(new Date());
    handleEventMonthChange(new Date());
    setConfirmSlots([]);
    setSlotSelected(selectedData);
    navigateToBack();
  };

  function compare(a, b) {
    if (a.date < b.date) {
      return -1;
    }
    if (a.date > b.date) {
      return 1;
    }
    return 0;
  }

  const sortArray = (data) => {
    const sortedData = [...data]?.sort((a, b) => {
      return new Date(a.fromDateTime) - new Date(b.fromDateTime);
    });
    setBookedSlotsData(sortedData);
  };

  const handleNext = () => {
    setConfirmSlots(selectedSingleData.sort(compare));
    // setSlotSelected(selectedData);
    sortArray(selectedData);
    navigateToNext();
  };

  // const onSlotSelection = (id, slotItem) => {
  //   setActiveSlotId((prevIds) => [...prevIds, id]);
  //   addSlotId(id, slotItem);
  // };

  const onSlotSelection = (id, slotItem) => {
    if (reschedule) {
      setActiveSlotId([id]);
      addSlotId(id, slotItem);
    } else {
      setActiveSlotId((prevIds) => [...prevIds, id]);
      addSlotId(id, slotItem);
    }
  };

  const addSlotId = (id, slot) => {
    const filteredData = slotSelected?.filter((singleDaySlot) => singleDaySlot?.fromDateTime === slot?.fromDateTime);
    const updatedSelection = filteredData?.map((singleDay) => {
      return {
        slotDate: singleDay.date,
        coachSlot: singleDay.coachSlot,
        coachSubSlot: singleDay.slotId,
        fromDateTime: singleDay.fromDateTime,
        toDateTime: singleDay.toDateTime,
      };
    });

    setSelectedSingleData(updatedSelection);
    const hasSameObject = selectedData.map((item) => item.fromDateTime === slot.fromDateTime);
    const hasObjValidation = hasSameObject.every((i) => i === false);
    if (hasObjValidation) {
      setSelectedData((prevData) => [...prevData, ...updatedSelection]);
    } else {
      removeSlotId(id, slot);
    }
  };

  const removeSlotId = (id, slot) => {
    setActiveSlotId(activeSlotId.filter((item) => item !== id));
    const updatedSelection = selectedData.filter((item) => item.fromDateTime !== slot.fromDateTime);
    setSelectedData(updatedSelection);
  };

  // const checkSlotExist = () => {
  //   let checkFlag = true;
  //   selectedData?.length !== 0 &&
  //     selectedData.map((singleDay) => {
  //       if (Object.keys(singleDay)?.length > 0) {
  //         checkFlag = false;
  //       }
  //       return checkFlag;
  //     });
  //   setDisabled(checkFlag);
  // };

  const disabledFn = () => {
    if (selectedData.length >= 1) {
      return setDisabled(false);
    } else if (selectedData.length >= minBookigCount) {
      return setDisabled(false);
    } else {
      return setDisabled(true);
    }
  };

  const activeSlotFn = (slot) => {
    const aciveSlotData = activeSlotId.filter((item) => item === slot?._id).map((item) => item === slot?._id)[0];
    return aciveSlotData;
  };

  return (
    <>
      <div className="page-heading flex-sm-row flex-column">
        <h4 className="col hd-txt mt-2">Choose a Time Slot </h4>
        <div className="d-flex gap-2 flex-sm-row flex-column">
          {!hasGoal ? (
            <Button variant="clr-primary" className="btn-add  back-continue" onClick={handleBackAction}>
              Back
            </Button>
          ) : (
            <></>
          )}
          <Button variant="clr-primary" className="btn-add back-continue" disabled={disabled} onClick={handleNext}>
            Continue
          </Button>
        </div>
      </div>
      <div className="">
        <div className="skill-card-no-click col-lg-4 ms-0">
        <div className="profile-wrap coach-Img"  style={{
                backgroundImage:`url(${EnvConfig.MediaBase}/${coachData?.assetsDir}/${coachData?.profileImage})`,
              backgroundPosition: "center", /* Center the image */
              backgroundRepeat:'no-repeat', /* Do not repeat the image */
              backgroundSize: "cover", /* Resize the background image to cover the entire container*/
              borderRadius:"50%"
              }}>
                  
                </div>
          {/* <div className="profile-wrap">
            <img
              className="coach-Img mx-2"
              src={`${EnvConfig.MediaBase}/${coachData?.assetsDir}/${coachData?.profileImage}`}
              alt="user pic"
              onError={handleImageError}
            />
          </div> */}
          <div className="skill-detail align-self-center">
            <div className="profile-info">
              <div className="title coach-name" style={{paddingTop:"0px"}}>{`${coachData?.firstName} ${coachData?.lastName}`}</div>
            </div>
            <div className="header-text">{coachData?.label}</div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-6">
            <div>
              <h4 className="col hd-txt">Date</h4>
              <CustomizedCalender
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                dateAvailable={dateAvailable}
                handleEventMonthChange={handleEventMonthChange}
                bookedDates={bookedDates}
                endDate={endDate}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <h4 className="col hd-txt">Time</h4>
            </div>
            <div>
              {timeSlot?.length > 0 ? (
                timeSlot?.map((slot) => (
                  <div
                    key={slot?._id}
                    role="button"
                    onClick={() => {
                      if (slot.slotStatus !== "confirmed") {
                        onSlotSelection(slot?._id, slot);
                      }
                    }}
                    className={
                      slot.slotStatus === "confirmed"
                        ? "unavailable-slots"
                        : activeSlotFn(slot)
                        ? "selected-slot-id"
                        : "selected-slot"
                    }
                  >
                    {formatTime(slot.fromDateTime)}
                  </div>
                ))
              ) : (
                <div>No Slots Available</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StepSlotSelection;
