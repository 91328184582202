import { NavLink, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, Row, Col, Nav, Navbar, Dropdown } from "react-bootstrap";
import Profile from "../../assets/images/avtar.png";
import { clearAppData } from "../../stores/store";
import EnvConfig from "../../envConfig";

function Header({ navToggled }) {
  const { authState, appState } = useSelector((state) => state);
  const profileImage = `${EnvConfig?.MediaBase}/${authState?.user?.assetsDir}/${authState?.user?.profileImage}`;

  const handleImageError = (e) => {
    e.target.src = Profile; // Set the default image source
  };

  function logOut() {
    clearAppData();
  }

  return (
    <Navbar expand="lg" className="">
      <Container fluid={true}>
        {navToggled ? (
          <>
            <Navbar.Toggle />
            <Navbar.Collapse>
              <Nav className="me-auto">
                <Nav.Link as={NavLink} to="dashboard">
                  Dashboard
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </>
        ) : (
          <></>
        )}

        <h2 className="header-title">{appState.routeData.pageTitle}</h2>

        <Dropdown className="view-profile">
          <Dropdown.Toggle variant="" id="dropdown-basic">
            <div className="profile-wrap">
              {/* <img className="thumbnail-image" src={profileImage} alt="user pic" onError={handleImageError} /> */}
              <div className="profile-wrap thumbnail-image"  style={{
                padding:'3px',
                backgroundImage:`url(${profileImage})`,
              backgroundPosition: "center", /* Center the image */
              backgroundRepeat:'no-repeat', /* Do not repeat the image */
              backgroundSize: "cover", /* Resize the background image to cover the entire container*/
              borderRadius:"50%",
              }}>
                  
                </div>
              <div className="profile-info">
                <div className="title">{`${authState.user.username}`}</div>
                <div className="subtitle">{authState.user.role?.roleName}</div>
              </div>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className="drop-down-list">
            <Dropdown.Item as={Link} to="profile/edit">
              Edit Profile
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="changePassword">
              Change Password
            </Dropdown.Item>
            <Dropdown.Item onClick={logOut}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Container>
    </Navbar>
  );
}

export default Header;
