import {
  useCreateChatClient,
  Chat,
  Channel,
  ChannelHeader,
  ChannelList,
  MessageInput,
  MessageList,
  Thread,
  Window,
} from "stream-chat-react";

import "./streamChat.css";

import "stream-chat-react/dist/css/v2/index.css";
import { ChatConfig } from "../../ChatConfig";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import userService from "../../services/user.service";
import EnvConfig from "../../envConfig";
import userRoleService from "../../services/userRole.service";
import { setRouteData } from "../../stores/appSlice";

// const user: User = {
//   id: userId,
//   name: userName,
//   image: `https://getstream.io/random_png/?name=${userName}`,
// };

const StreamChatList = ({ pageTitle }) => {
  const userData = useSelector((store) => {
    return store.authState.user;
  });
  const [userDetails, setUserDetail] = useState(null);
  const [showMessage, setShowMessage] = useState(true)

  const dispatch = useDispatch();

  const [Details, setChannelDetail] = useState(null);
  const sort = { last_message_at: -1 };
  const filters = {
    type: "messaging",
    members: { $in: [userData.StreamChat.id] },
  };
  const options = {
    limit: 10,
  };

  

  const CustomChannelPreview = (props) => {
    const {
      channel,
      active,
      displayTitle,
      displayImage,
      unread,
      latestMessage,
      setActiveChannel,
    } = props;
    console.log(props, "..props");

    const { messages } = channel.state;
    const messagePreview = messages[messages.length - 1]?.text?.slice(0, 30);
    let receiverId = props.channel.id.replace(userData.StreamChat.id, "");
    let username = userDetails?.[receiverId]?.username ?? null;
    let image =
      (userDetails?.[receiverId]?.image ?? null) &&
      `${EnvConfig?.MediaBase}/${userDetails?.[receiverId]?.image}`;
    function setDetails() {
      setActiveChannel?.(channel);
      setChannelDetail({
        username: username,
        image: image,
        fullName: userDetails?.[receiverId]?.fullName
      });
    }

    return (
      <div
        onClick={setDetails}
        className={`conversation-item ${active && "selected"}`}
      >
        <div>
          <img
            src={image ?? displayImage}
            className="thumbnail-image"
            alt="channel-image"
          />
        </div>
        <div className="detail">
          <div className={`title titlepading ${active && "selected"}`}>
            {userDetails?.[receiverId]?.fullName ?? displayTitle}
          </div>
          {latestMessage && (
            <div style={{ fontSize: "14px" }}>{latestMessage}</div>
          )}
        </div>
        {unread ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flex: 1,
            }}
          >
            <div className="count">{unread}</div>
          </div>
        ) : null}
      </div>
    );
  };
  let image = `${EnvConfig?.MediaBase}/${userData?.assetsDir}/${userData?.profileImage}`;

  const client = ChatConfig(userData.StreamChat, userData.username, image);

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    getDetail();
  }, []);

  async function getDetail() {
    try {
      const resp = await trackPromise(userService.getStreamUserDetail());
      console.log(resp.data.data);
      let message = resp.data.data;
      setShowMessage(Object.keys(message).length <= 1)
      setUserDetail(resp.data.data);
      let showMessage=Object.keys(resp.data.data);
      setShowMessage(showMessage.length<=1);
    } catch (error) {
      console.log(error);
    }
  }
  if (!client) return <div>Setting up client & connection...</div>;

  return (
   
    <>
   { userDetails?
      <div className="container-fluid">
        <div className="row">
          <Chat client={client}>
            {showMessage ?
              <Message />
              :
              <div className="col-md-4  borderdiv heightclass">
                <ChannelList
                  Preview={CustomChannelPreview}
                  filters={filters}
                  sort={sort}
                  options={options}
                  on
                />
              </div>
            }
            <div className="col-md-8 borderdiv  heightclass">
              {Details && (
                <Channel >
                  <Window>
                    <ChannelHeader
                      image={Details.image}
                      title={Details.fullName}
                    />
                    <MessageList />
                    <MessageInput />
                  </Window>
                  <Thread />
                </Channel>
              )}
            </div>
          </Chat>
        </div>
      </div>
      :
      null
      }
    </>
      );
};



function MessageComponent(){

  return(
    <div style={{
      maxWidth:'600px',
      display:'flex',
      marginTop:'100px',
      fontSize:'20px',
      textAlign:'center',
      // fontWeight:'600'
    }}>
Chat messaging will be available once a coaching partnership has been established.
</div>
  )
}
export default StreamChatList;


function Message() {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <p style={{
        fontSize: '20px',
        fontWeight: 'bold',
        textAlign: 'center',
        maxWidth:'450px',
        paddingTop:'80px'
      }}>Chat messaging will be available once a coaching partnership has been established.</p>
    </div>
  )
}
