import { Button, Col, Row } from "react-bootstrap";
import EnvConfig from "../../envConfig";
import "./wizard.css";

function StepCoachSelection(props) {
  const {
    coachesList,
    handleCoachSelect,
    handleImageError,
    navigateToNext,
    coachesListReadMore,
    coachId,
    navigateToBack,
    setCoachProfile,
    viewMore,
  } = props;

  const handleBackAction = () => {
    handleCoachSelect(null);
    navigateToBack();
  };

  return (
    <>
      <div className="page-heading ">
        <h4 className="col hd-txt  ">Choose a Coach</h4>
        <Button variant="clr-primary" className="btn-add back-continue mx-4 " onClick={handleBackAction}>
          Back
        </Button>
        {/* <Button variant="clr-primary" className="btn-add back-continue " disabled={!coachId} onClick={navigateToNext}>
          Continue
        </Button> */}
      </div>

      <Row className="skill-container-coach d-flex flex-column">
        {coachesList &&
          coachesList?.map((item, index) => {
            let  backgroundImage=`${EnvConfig.MediaBase}/${item?.assetsDir}/${item?.profileImage}`;

          return(  <Col key={index} className="col-lg-5">
              <div
                className={coachId === item?.id ? "skill-card-coach" : "skill-card-coach"}
                onClick={() => {
                  handleCoachSelect(item);
                }}
              >
                <div style={{height:'100%',paddingTop:"10px"}}>
                <div className="profile-wrap"  style={{width:"110px",height:"110px",
                backgroundImage:`url(${backgroundImage})`,
              backgroundPosition: "center", /* Center the image */
              backgroundRepeat:'no-repeat', /* Do not repeat the image */
              backgroundSize: "cover", /* Resize the background image to cover the entire container*/
              borderRadius:"50%"
              }}>
                  {/* <img
                    className="coach-Img me-2"
                    src={`${EnvConfig.MediaBase}/${item?.assetsDir}/${item?.profileImage}`}
                    alt="user pic"
                    onError={handleImageError}
                  /> */}
                </div>
                </div>
                <div className="skill-detail">
                  <div className="profile-info">
                    <div className="coach-name">{`${item?.firstName} ${item?.lastName}`}</div>
                    <div><span style={{color:"#b18c93",fontSize:"18px"}} >★   </span>  {Math.round(item?.avgRating * 10) / 10}</div>
                  </div>
                  <div className="aboutself">{item?.aboutSelf}</div>
                  <div className="mt-2 d-flex justify-content-between gap-2">
                    <Button
                      variant={coachId === item?.id ? "clr-primary" : "clr-primary"}
                      onClick={() => {
                        handleCoachSelect(item);
                        setCoachProfile(1);
                      }}
                    >
                      View Profile
                    </Button>
                  </div>
                </div>
              </div>
            </Col>)
})}
      </Row>

      <Row className="mt-3 mx-2">
        <Col className="col-lg-5 flexCenter">
          {coachesList?.length !== coachesListReadMore?.length ? (
            <Button
              variant={"clr-primary"}
              onClick={() => {
                viewMore();
              }}
            >
              View More
            </Button>
          ) : null}
        </Col>
      </Row>
    </>
  );
}

export default StepCoachSelection;
